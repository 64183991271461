import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    document.addEventListener('goal', this.call)
    if (window.ct) {
      setTimeout(this.setCallTouchSession, 3000)
    }
  }

  disconnect () {
    document.removeEventListener('goal', this.call)
  }

  call = event => {
    const { target } = event.detail
    window.ym(50330590, 'reachGoal', target)
    console.log(target)

    if(target != 'brokers_phone'){
      window.location.replace("https://sovcominvest.ru/spasibo")
    }
  }

  setCallTouchSession () {
    let arr = Array.from(document.getElementsByClassName('calltouch_session_id'))
    arr.forEach(el => el.value = window.ct('calltracking_params','7yrxrinz').sessionId)
    arr.forEach(el => console.log(el.value))
  }
}
