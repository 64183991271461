import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'close']

  async connect () {
    let closeTg = await this.getSessionData()

    if (closeTg !== true){
      await this.sleep(5000)
      this.modalTarget.classList.add('modal-banner-active')
      await this.sleep(3000)
      this.closeTarget.classList.add('modal-banner-close-active')
    }
  }

  sleep = ms => new Promise(r => setTimeout(r, ms));

  async getSessionData(){
    return Boolean(sessionStorage.getItem('tgBannerClose'));
  }

  close(){
    this.modalTarget.classList.remove('modal-banner-active')
    sessionStorage.setItem("tgBannerClose", true);
  }
}
